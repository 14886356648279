export const getAndSaveParams = () => {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search.substring(1)));

  const sessionParams = sessionStorage;

  let apartments = urlParams['apartments'];
  if (!apartments) {
    apartments = `${
      urlParams['aid'] || sessionParams['apartmentId'] || getEnvValue('APARTMENT_ID')
    }##${urlParams['aname'] || sessionParams['apartmentName'] || getEnvValue('APARTMENT_NAME')}`;
  }

  const params = {
    organisation: urlParams['org'] || sessionParams['organisation'] || getEnvValue('ORGANISATION'),
    tenant: urlParams['tenant'] || sessionParams['tenant'] || getEnvValue('TENANT'),
    token: urlParams['token'] || sessionParams['token'] || getEnvValue('TOKEN'),
    clientType: urlParams['ctype'] || sessionParams['clientType'] || getEnvValue('CLIENT_TYPE'),
    language: urlParams['lang'] || sessionParams['language'] || getEnvValue('LANGUAGE') || 'en',
    projectId: urlParams['pid'] || sessionParams['projectId'] || getEnvValue('PROJECT_ID'),
    projectName: urlParams['pname'] || sessionParams['projectName'] || getEnvValue('PROJECT_NAME'),
    apartments,
    primaryColor:
      urlParams['pcolor'] ||
      sessionParams['primaryColor'] ||
      process.env.REACT_APP_COLOR ||
      'B7835F'
  };

  for (const [key, value] of Object.entries(params)) {
    if (!value) throw Error('No value for ' + key);

    sessionStorage.setItem(key, value);
  }

  return params;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const global: any;

export const getEnvValue = (key: string) => {
  if (process.env[`REACT_APP_${key}`]) return process.env[`REACT_APP_${key}`];
  else if (global.env) return global.env[key];

  return '';
};
