import ReactDOM from 'react-dom';
import { MessagingUI, MessagingClientType } from '@groupbuilderoy/messaging-client';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { getAndSaveParams, getEnvValue } from './utils';
import { Alert } from '@material-ui/lab';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'local') {
  Sentry.init({
    // TOOD: for some reason SENTRY_DSN env does not work through rancher, so this needs to be hard coded for now.
    dsn: 'https://01feb90b31084ef3b181f1c0fdd1c3c8@o100103.ingest.sentry.io/4504354331230208', //getEnvValue('SENTRY_DSN'),
    enabled: getEnvValue('USE_SENTRY') === 'true',
    environment: getEnvValue('SENTRY_ENV') || process.env.NODE_ENV
  });
}

let params: any = null;
let error: any = null;
try {
  params = getAndSaveParams() as any;
} catch (e) {
  error = e;
  console.error(e);
  Sentry.captureException(e);
}

const theme = createTheme({
  palette: {
    primary: {
      main: `#${params ? params.primaryColor : 'B7835F'}`
    },
    secondary: {
      main: `#EFEEE4`
    },
    text: {
      primary: '#353535'
    }
  }
});

const apartments = decodeURIComponent(params.apartments)
  .split(';;')
  .map((apartmentPair) => {
    const [id, name] = apartmentPair.split('##');
    return { id: Number(id), name };
  });

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {error ? (
      <Alert severity='error'>{`${error}`}</Alert>
    ) : (
      <MessagingUI
        token={params.token}
        organisationId={params.organisation}
        tenantId={params.tenant}
        language={params.language}
        project={{ id: Number(params.projectId), name: params.projectName }}
        apartments={apartments}
        theme={theme}
        clientType={params.clientType as MessagingClientType}
      />
    )}
  </ThemeProvider>,
  document.getElementById('root')
);
